<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

definePageMeta({
  key: route => route.path,
  title: 'pages.title.index',
})

const state = useStore()
const runtimeConfig = useRuntimeConfig()
const companyData = runtimeConfig.public.company
const route = useRoute()
const router = useRouter()

const { ecommerce } = useAnalytics()

const container = ref<HTMLElement | null>(null)

function scrollToContainer() {
  if (container.value == null)
    return

  container.value.scrollIntoView({ behavior: 'smooth' })
}

const term = useRouteQuery('q', '') // search query
const take = useRouteQuery('take', '40') // pagination
const page = useRouteQuery('page', '1', {
  transform: (v) => {
    const page = parseInt(v)
    return isNaN(page) ? 1 : page
  },
})

const drawers = useDrawers()

function handleNavigationClick() {
  drawers.value.navigation.open = true
}
</script>

<!-- <template>
  <HomepageHero />
  <HomepageNavigation />
  <main>
  </main>

  <div ref="container" class="mx-auto max-w-3xl px4 pb8 lg:max-w-[88rem] lg:px8 md:max-w-4xl md:-m-t-2 sm:px6 sm:pb12">
    <div id="bestsellers" class="mx-auto max-w-3xl lg:max-w-[88rem] lg:px6 md:max-w-4xl sm:px4 sm:pb12 sm:pt2">
      <ProductGrid key="index" :page="page" :take="60" :sort="{ popularityScore: SortOrder.DESC }" @update:page="page = $event" />
    </div>
  </div>
</template>
-->
<template>
  <div class="w-full">
    <div class="inset-0 w-full overflow-hidden from-#f5c541/100 via-#f5c541/65 to-#5E3A0F/10 bg-gradient-to-b px15 py5">
      <div class="flex flex-col-reverse md:flex-row">
        <div class="flex basis-1/2 justify-center">
          <img
            src="/products/wepik-export-20240123112224kCDp.png" alt="banner-img"
            class="w-[50%] -m-y-2 md:min-w-65 md:w-120 md:-m-y-15"
          >
        </div>
        <div class="flex flex-col items-center justify-center gap-y-4 text-white md:items-end">
          <h1 class="text-center text-4xl font-semibold md:text-right md:text-5xl">
            Üdvözöljük a Belio világában!
          </h1>
          <h5 class="text-center text-xl md:text-right md:text-2xl">
            Lépjen be abba a világba, ahol az elegancia találkozik a minőséggel.<br>
            Fedezze fel exkluzív bőr táskáink kollekcióját, amelyek kiemelik egyedi stílusát.
          </h5>
          <StoreLogo class="h-12 text-white lg:mt-5" />
        </div>
      </div>
    </div>
    <div class="mx-auto max-w-7xl px-0 lg:px-8 sm:px-6">
      <div class="grid grid-rows-2 gap-1 px-5 py-0 md:grid-cols-2 md:grid-rows-1 md:gap-4 md:p-5">
        <div class="h-full flex items-center justify-center">
          <div class="flex flex-col items-center justify-center rounded-md bg-slate-50 p-5 md:px-10 md:py-5">
            <p>
              Ez a nagy méretű olasz bőr táska ideális választás azoknak a hölgyeknek, akik preferálják a nagyobb
              táskákat,
              értékelik a minőséget és a természetes bőr kellemes illatát.
            </p>
            <p>
              Ez a női "shopper" táska nem csak praktikus társ a bevásárláshoz, de szignifikánsan feldobja az
              öltözékedet
              és erősíti önbizalmad. Precízen elkészítették a maximális kényelem érdekében.
            </p>
            <p>
              A táskát könnyedén lehet kézben hordani, vagy egy kiegészítő pánttal az vállon is. A bélés két oldalas
              rekeszt kínál - az egyik kisebb a mobiltelefonnak, a másik pedig nagyobb cipzáras zsebbel.
            </p>
            <p>
              A hátoldalon praktikus cipzáras zseget sem hagytak ki. A táska ezüst színű kiegészítőkkel van ellátva,
              amelyek elegáns és modern megjelenést kölcsönöznek.
            </p>
            <div class="my-2 flex place-self-start items-center gap-x-4">
              <NLink
                :to="localePath('/products/247805/nagy-bor-noi-bevasarlotaska')"
                class="rounded-md bg-primary-2 px4 py1.5 text-white no-underline"
              >
                A TERMÉK MEGJELENÍTÉSE
              </NLink>
              <Price :regular="3484900" class="block" />
            </div>
          </div>
        </div>
        <div class="h-full flex items-center justify-center">
          <img
            src="/products/kozena-damska-kabelka-m8859-konakova-hneda-1.jpg" alt="konakova-hneda"
            class="h-auto max-w-auto md:h-120"
          >
        </div>
      </div>
      <div class="grid grid-cols-3 gap-1 px-5 py-0 md:gap-4 md:p-5">
        <div class="h-full flex items-center justify-center">
          <img
            src="/products/kozena-damska-kabelka-m8859-konakova-hneda-2.jpg" alt="konakova-hneda"
            class="h-auto max-w-auto md:h-50"
          >
        </div>
        <div class="h-full flex items-center justify-center">
          <img
            src="/products/kozena-damska-kabelka-m8859-konakova-hneda-3.jpg" alt="konakova-hneda"
            class="h-auto max-w-auto md:h-50"
          >
        </div>
        <div class="h-full flex items-center justify-center">
          <img
            src="/products/kozena-damska-kabelka-m8859-konakova-hneda-4.jpg" alt="konakova-hneda"
            class="h-auto max-w-auto md:h-50"
          >
        </div>
      </div>
    </div>
    <section aria-labelledby="collections-heading" class="mt10">
      <div class="mx-auto max-w-7xl px-4 lg:px-8 sm:px-6">
        <h2 class="text-2xl text-gray-900 font-bold tracking-tight">
          {{ $t('approval_page.other_products') }}
        </h2>
        <div class="mx-auto max-w-2xl py-4 lg:max-w-none">
          <ProductGrid />
        </div>
      </div>
    </section>
    <div class="inset-0 w-full overflow-hidden from-#5E3A0F/10 via-#f5c541/65 to-#f5c541/95 bg-gradient-to-b px15 py5">
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col items-center justify-center gap-y-4 text-white md:items-start">
          <h1 class="text-center text-4xl font-semibold md:text-left md:text-5xl">
            Tanácsra van szüksége a választáshoz?
          </h1>
          <h5 class="text-center text-xl md:text-left md:text-2xl">
            Ha bármilyen kérdése van, ne habozzon kapcsolatba lépni velünk<br>
            Szívesen adunk tanácsot
          </h5>
          <h5 class="text-[1.5rem] text-white lg:mt-5 md:mt-5 lg:text-[1.5rem] md:text-[1rem]">
            <a :href="`mailto:${companyData.emailAddress}`">
              {{ companyData.emailAddress }}
            </a>
          </h5>
          <StoreLogo class="h-12 text-white lg:mt-5" />
        </div>
        <div class="flex basis-1/2 justify-center">
          <img
            src="/products/wepik-export-20240123111854uyTD.png" alt="banner-img"
            class="w-[50%] -m-y-2 md:min-w-65 md:w-120 md:-m-y-15"
          >
        </div>
      </div>
    </div>
  </div>
</template>
